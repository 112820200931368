$(document).ready(function() {

  // Mostra/esconde senha input password
  $('.show-password').click(function(){
		let inputPassword = $(this).closest(":has(input)").find('input')
		if (inputPassword.attr('type') === 'password') {
			inputPassword.attr('type', 'text')
		} else {
			inputPassword.attr('type', 'password')
		}
  })

  $('#user_platform').val(navigator.platform || false)
})